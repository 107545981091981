<!--
 * @Descripttion: 产品
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-05-11 09:42:46
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-08-01 17:54:29
-->
<template>
    <div class="webProduct">
        <div class="d-w-title flex-sbc">
            <div class="wt-title flex-wc" @click="clickGoBalck">
                <div class="balck">
                    <i class="el-icon-arrow-left"></i>
                </div>
                <div class="title">价格</div>
            </div>
        </div>
        <div class="wp-tab-wrap">
            <div class="tw-title">
                <div class="title">获取适合的有库云PRO版本</div>
                <div class="flex-sbc">
                    <img class="icon" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/0d8eef5c8f94a07ecdf36fb0c5934be7.png" alt="有库云进销存">
                    <div class="tip">我们将永久免费开放基础版本，不限制Windows端、网页端及小程序端使用。并提供未来所有的版本更新。</div>
                </div>
            </div>
            <div class="tw-tab">
                <div class="tw-t-title flex-sbc">
                    <div class="tab" :class="tabIndex == index?'active':''"
                        v-for="(item,index) in tabList" :key="index"
                        @click="clickTab(item,index)">
                        {{item.title}}
                    </div>
                </div>
                <div class="tw-t-content flex-coc">
                    <div class="tc-num">{{info.num}}</div>
                    <div class="tc-price" v-if="info.price == '免费'"><span>{{info.price}}</span></div>
                    <div class="tc-price" v-else>￥<span>{{info.price}}</span>/年</div>
                    <div @click="clickOpen" class="tc-btn flex-cc">立即开通PRO版</div>
                    <img class="tc-img" :src="info.image" alt="">
                </div>
            </div>
        </div>
        <div class="wp-tip-wrap">东有创新 © 2024 Ukoocloud  粤ICP备2023127805号-1 </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            tabList: [{
                title: '基础版',
                price: '免费',
                num: '共计1个账号空间',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240801/3dbfb92d8d30b1140b77cedc783a3b5f.jpg'
            },{
                title: '3人团队版',
                price: '398',
                num: '共计4个账号空间',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            },{
                title: '10人团队版',
                price: '998',
                num: '共计11个账号空间',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            },{
                title: '无限账号版',
                price: '1598',
                num: '不限账号空间 ',
                image: 'https://ukoo.oss-cn-beijing.aliyuncs.com/system/20240511/5900199226d23467cb9178245983ecb6.png'
            }],
            tabIndex: 0,
            info: {},
            activeName: '',
            question: [{
                isShow: false,
                title: '软件系统是否可以不同设备间实现数据实时同步？',
                content: '有库云作为云进销存系统，数据存储在云端。只要设备有接入网络，不同设备即可实现数据实时同步。'
            },{
                isShow: false,
                title: '安装是否会占用手机或电脑设备大量内存？',
                content: '云服务的好处就是，基本数据运行、存储都通过云服务器来完成，并不占用太多本地存储空间，且始终运行流畅。'
            },{
                isShow: false,
                title: '版本账号空间数不够用，能否进行账号空间升级？',
                content: '可以的，账号空间可以选择匹配的有库云版本套餐进行升级，但需要注意的是版本无法降级。'
            },{
                isShow: false,
                title: '软件功能担心团队成员吃不透，用不起来这么办？',
                content: '有库云进销存非常简单、易上手，基本团队成员所要掌握的操作流程都极其简单，遵循本身的业务逻辑，同时我们也会认真耐心的进行培训讲解。但不论如何，最关键也是需要经营者的重视，引导鼓励大家改变，养成习惯。'
            },{
                isShow: false,
                title: '有库云能否在苹果电脑MAC上运行？',
                content: '有库云应用端有网页版、小程序版、Windows版，在苹果电脑上登录网页版即可兼容使用。'
            },{
                title: '使用有库云是否需要额外购买打印设备？',
                content: '通过电脑端连接打印的，无需另行购买打印设备，不论是针式打印机或喷墨打印机有库云都支持。若业务需要支持手机端WIFI远程无线打印的，则需要根据我们提供的配置另行购买。'
            },{
                isShow: false,
                title: '是否支持多仓库、多门店管理？',
                content: '有库云所有版本均支持多仓库、多门店的管理，不额外收取费用。'
            },{
                isShow: false,
                title: '打印销售单、出库单以及购销合同时能否自定义内容？',
                content: '销售单 、出库单备注信息以及收款二维码可以根据自身需求进行自定义。购销合同除商品信息之外的内容可根据合作细节进行自定义。'
            },{
                isShow: false,
                title: '开单时是否有不同客户历史价格记录？',
                content: '同商品不同时期采购价格不同，我们会根据采购价格波动实时计算商品加权平均成本，计算库存金额，确保准确性。'
            },{
                isShow: false,
                title: '我们有些员工负责多个岗位的内容，是否需要多个账号？',
                content: '不用，只需把该员工添加到有库云系统中，然后根据需要进行权限的分配即可。'
            },]
        }
    },
    mounted () {
        this.info = this.tabList[0]
    },
    methods: {
        clickTab(item,index){
            this.tabIndex = index;
            this.info = item
        },
        // 预约演示
        clickDemo(){
            this.$router.push("./webDemonstration")
        },
        // 立即开通
        clickOpen(){
            this.$router.push("./webOpnePro")
        },
        // 注册试用
        clickRegisterTry(){
            this.$router.push("./webRegisterTry")
        },
        clickGoBalck(){
            this.$router.go(-1) 
        },
    },
}
</script>

<style lang='scss'>
.webProduct{
    .d-w-title{
        height: 50px;
        background: #fff;
        padding: 0 20px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        .wt-title{
            width: 100%;
            .balck{
                .el-icon-arrow-left{
                    font-size: 18px;
                    color: #101010;
                    font-weight: bold;
                }
            }
            .title{
                width: calc(100% - 50px);
                font-weight: bold;
                font-size: 16px;
                color: #101010;
                text-align: center;
            }
        }
        .wt-logo{
            .logo{
                width: 70px;
                height: 15px;
            }
        }
    }
    .wp-tab-wrap{
        background: #eff5fd;
        padding: 30px 20px 30px 20px;
        .tw-title{
            .title{
                font-size: 20px;
                color: #101010;
                font-weight: bold;
                text-align: center;
            }
            .icon{
                width: 90px;
                height: 80px;
            }
            .tip{
                width: calc(100% - 100px);
                font-size: 12px;
                color: #000;
                padding: 10px 0;
                line-height: 22px;
            }
        }
        .tw-tab{
            background: #fff;
            border-radius: 8px;
            margin-top: 30px;
            padding: 10px 20px;
            .tw-t-title{
                border-bottom: 1px solid #d7d7d7;
                .tab{
                    font-size: 14px;
                    color: #808080;
                    padding: 15px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0);
                }
                .active{
                    color: #0068ff;
                    font-weight: bold;
                    border-bottom-color: #0068ff;
                }
            }
        }
        .tw-t-content{
            .tc-num{
                font-size: 14px;
                color: #7B7B7B;
                padding: 25px;
            }
            .tc-price{
                font-size: 12px;
                color: #04963a;
                span{
                    font-size: 24px;
                    font-weight: bold;
                }
            }
            .tc-btn{
                width: 100%;
                height: 46px;
                font-size: 14px;
                color: #fff;
                background: #3e6ae1;
                border-radius: 4px;
                margin: 25px 0;
            }
            .tc-img{
                width: 100%;
            }
        }
    }
    .wp-tip-wrap{
        font-size: 12px;
        color: #999;
        text-align: center;
        padding: 15px 0;
        background: #000;
    }
}
</style>
